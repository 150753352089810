<template>
    <div class="container-fluid p-4">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Time Select - Inline - 12 hrs</div>
                    <div class="card-body">
                        <b-time v-model="value" locale="en" :hour12="true"></b-time>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Time Select - Inline - 24 hrs</div>
                    <div class="card-body">
                        <b-time v-model="value" locale="en" :hour12="false"></b-time>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'Time',
    data() {
        return {
            value: null,
            state: 'disabled'
        }
    },
    computed: {
        disabled() {
            return this.state === 'disabled'
        },
        readonly() {
            return this.state === 'readonly'
        }
    },
    methods: {
        setNow() {
            const now = new Date()
            // Grab the HH:mm:ss part of the time string
            this.value = now.toTimeString().slice(0, 8)
        },
        clearTime() {
            this.value = ''
        }
    },
    mounted() {
        this.setNow();
    }
}
</script>
